<template>
	<div class="contain">
		<div class="breadcrumb">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/lease' }">设备租赁</el-breadcrumb-item>
				<!-- <el-breadcrumb-item :to="{ path: '/shopping' }">{{
          num == "1" ? "诊断" : "维修"
        }}</el-breadcrumb-item> -->
				<el-breadcrumb-item>{{ infoObj.name }}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="content">
			<div class="details pt-15">
				<el-row>
					<el-col :span="6">
						<div class="recomoutside">
							<div class="recomBlock mb-20">
								<div class="img"><img :src="photoURL + infoObj.mainImg" alt="" /></div>
								<div class="Recomword2">{{ infoObj.prodDesc }}</div>
								<div class="rate flex jc-center ai-center darkColor">
									<span>评价：</span>
									<el-rate v-model="score" disabled show-score text-color="#A4A9B0" score-template="{value}" :colors="colors"></el-rate>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="18">
						<div class="up">
							<table class="pl-15 pr-25">
								<tr class="Recomword1">{{ infoObj.name }}</tr>
								<tr class="flex">
									<span class="biaoti1">价格：</span>
									<span class="blueColor" style="font-size: 24px">
										{{ infoObj.skuList[activeIndex].priceType == 1 ? '￥' + infoObj.skuList[activeIndex].price : '洽谈' }}
									</span>
								</tr>
								<tr class="flex jc-between">
									<div class="upLeft flex">
										<span>说明：</span>
										<span class="px-10 biaoti1">{{ infoObj.buyDesc }}</span>
									</div>
									<div class="upright"></div>
								</tr>
								<tr class="flex">
									<span>物流：</span>
									<span class="biaoti1">{{ infoObj.city }}</span>
									<span class="px-10">品牌：</span>
									<span class="biaoti1">{{ infoObj.brandName }}</span>
									<span class="px-10">支付：</span>
									<span class="biaoti1">转账汇款</span>
									<!-- <span class="biaoti1" v-for="(item, i) in infoObj.payTypeList" :key="i">{{ item }}</span> -->
								</tr>
							</table>
						</div>
						<el-form label-width="90px">
							<el-form-item label="规格列表：" class="mt-10">
								<div class="specifications">
									<div class="specificationsTop">规格列表</div>
									<div class="specificationBox">
										<div class="specificationBoxitem">
											<div class="specificationBoxitem-item sp-one">型号</div>
											<div class="specificationBoxitem-item sp-one">押金</div>
											<div class="specificationBoxitem-item sp-one">租金</div>
											<div class="specificationBoxitem-item sp-one">库存量</div>
										</div>
										<div class="specificationBoxitem" v-for="(item, i) in infoObj.skuList" :key="i">
											<div class="specificationBoxitem-item sp-two">{{ item.number }}</div>
											<div class="specificationBoxitem-item sp-two">{{ item.deposit }}</div>
											<div class="specificationBoxitem-item sp-two">{{ item.price == 1 ? '￥' + item.price : '洽谈' }}</div>
											<div class="specificationBoxitem-item sp-two">{{ item.stock }}</div>
										</div>
									</div>
								</div>
							</el-form-item>
							<el-form-item label="规格型号：" class="mt-10" v-if="!skuId">
								<span
									@click="chooseType(item, index)"
									:class="{ active: activeIndex == index }"
									class="typeTag cursorPointer"
									v-for="(item, index) in infoObj.skuList"
									:key="index"
								>
									{{ item.number }}
								</span>
								
							</el-form-item>
							<el-form-item label="规格型号：" class="mt-10" v-if="skuId">
								<span
									v-for="(item, index) in infoObj.skuList"
									:class="{ active: skuId == item.id }"
									class="typeTag cursorPointer"
									:key="index"
								>
									{{ item.number }}
								</span>
								
							</el-form-item>
							<el-form-item label="租赁时间：">
								<el-date-picker
									v-model="dateValue"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									@change="dateChang"
									value-format="yyyy-MM-dd"
								></el-date-picker>
							</el-form-item>
							<el-form-item label="租赁数量：">
								<el-input
									class="input-value"
									:disabled="infoObj.skuList[activeIndex].stock < 1"
									:placeholder="'请租赁数量,剩余库存：' + infoObj.skuList[activeIndex].stock"
									v-model="order.goodsNum"
									type="number"
									:maxlength="infoObj.skuList[0].stock"
									:minlength="infoObj.skuList[0].minBuyQty"
								></el-input>
							</el-form-item>
							<el-form-item><el-button type="primary" @click="buy">立即租赁</el-button></el-form-item>
						</el-form>
					</el-col>
				</el-row>
			</div>
			<div class="goodsInfo"><div v-html="goodsDesc"></div></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			num: this.$route.query.num,
			activeIndex: 0,
			id: this.$route.query.id,
			skuId: this.$route.query.skuId,
			typeId: '',
			colors: ['#FF9F0A', '#FF9F0A', '#FF9F0A'],
			infoObj: null,
			goodsDesc: '',
			order: {
				goodsNum: null,
				skuId: '',
				rentEndTime: '',
				rentStartTime: ''
			},
			dateValue: '',
			score:5
		};
	},
	methods: {
		//   获取个人信息
		personalInfo() {
			this.$get('/shop-api/mall/rent/detail', {
				id: this.id
			}).then(data => {
				if (data.code == 1000) {
					this.infoObj = data.data;
					let fwb = data.data.detailPc;
					this.goodsDesc = fwb.replace(/< img/g, '<img style="width:100%"');
					this.goodsDesc = this.goodsDesc.replace('http://smheee.com:8093', 'https://file.smheee.com');
				} else if (data.code === 1003) {
					this.$message('请登录！');
				}
			});
		},
		// 选择分类
		chooseType(item, index) {
			this.activeIndex = index;
			this.typeId = item.id;
		},
		dateChang() {
			this.order.rentStartTime = this.dateValue[0];
			this.order.rentEndTime = this.dateValue[1];
		},
		// 租赁时带参类型ID跟时间
		buy() {
			if (this.order.rentStartTime == '') {
				this.$message.error('租赁时间不能为空');
				return;
			}
			if (this.order.goodsNum < 1) {
				this.$message.error('商品数量不能为0');
				return;
			}
			if (this.order.goodsNum > this.infoObj.skuList[this.activeIndex].stock) {
				this.$message.error('商品数量不足');
				return;
			}

			if (this.order.skuId == '') {
				this.order.skuId = this.infoObj.skuList[this.activeIndex].id;
			}
			this.order.brandName = this.infoObj.brandName;
			this.order.name = this.infoObj.name;
			let obj = Object.assign(this.order, this.infoObj.skuList[this.activeIndex]);
			this.$router.push(`/leaseOrder?searchInfo=${JSON.stringify(obj)}`);
			// this.$router.push({
			//   path:'/diagnosisOrder',
			//   query:{
			//     modifyTime:this.modifyTime,
			//     typeId: this.typeId
			//   }
			// })
		}
	},
	mounted() {
		this.personalInfo();
		this.Bus.$emit("headerSearch2",{activeMenu:1})
	}
};
</script>

<style lang="scss" scoped>
* {
	.contain {
		background-color: #f0f2f5;
		padding-bottom: 30px;

		.breadcrumb {
			letter-spacing: 1px;
			width: 1440px;
			height: 40px;
			color: #8c8c8c;
			background-color: #efeff4;
			margin: 0 auto;

			.el-breadcrumb {
				display: flex;
				align-items: center;
				height: 40px;
			}
		}

		.content {
			width: 1440px;
			margin: 15px auto;
			background-color: #f0f2f5;

			.details {
				background-color: #fffffd;
				.Recomword1 {
					font-weight: 600;
					color: #262626;
					font-size: 20px;
				}
				.recomoutside {
					display: flex;
					justify-content: center;
					align-items: center;

					.recomBlock {
						margin: 0 auto;
						width: 340px;
						text-align: center;

						.img {
							width: 300px;
							height: 300px;

							img {
								border-radius: 5%;
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
						}

						

						.Recomword2 {
							color: #888888;
							font-size: 16px;
							margin: 12px 0;
						}

						.rate {
							margin: 20px 0;
						}
					}
				}

				.up {
					table {
						padding: 20px 15px;
						width: 97%;
						background-color: #f4f9ff;
						border-radius: 8px;
						
						tr {
							// height: 40px;
							line-height: 34px;
						}
					}
				}

				.typeTag {
					font-weight: 400;
					font-size: 16px;
					color: #262626;
					padding: 8px;
					margin-right: 10px;
					line-height: 40px;
					border-radius: 4px;
					background-color: #f5f5f5;
				}

				.el-button {
					width: 200px;
					height: 60px;
					background-color: #4a99f6;
					border-radius: 8px;
				}
			}
		}

		.goodsInfo {
			letter-spacing: 1px;
			width: 100%;
			margin-top: 10px;
			background-color: #ffffff;
			img {
				width: 100%;
			}
			.title {
				height: 60px;
				line-height: 60px;
				background-color: #f5f5f5;
			}

			.word1 {
				color: #262626;
				font-size: 16px;
				font-weight: bold;
				padding-top: 15px;
			}

			.word2 {
				span {
					padding-top: 10px;
				}

				p {
					color: #595959;
					padding: 10px 0;
				}
			}
		}
	}
}

.evaluation {
	height: 400px;
	background-color: white;
}

.active {
	border-radius: 4px;
	border: 1px solid #4a99f6;
	background-color: #f4f9ff !important;
	color: #4a99f6 !important;
}

// 组件里的样式
::v-deep .el-tabs--border-card > .el-tabs__content {
	padding: 0;
}

::v-deep .el-tabs--border-card > .el-tabs__header {
	height: 54px;
}

::v-deep .el-button {
	width: 134px;
}

.specifications {
	width: 90%;
	background: #ffffff;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	border-radius: 6px 6px 0px 0px;
}

.specificationsTop {
	padding: 0 8px;
	width: 100%;
	height: 39px;
	background: #e6f7ff;
	border-radius: 4px 4px 0px 0px;
	border: 1px solid #bae7ff;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 39px;
}

.specificationBox {
	width: 100%;
	border-left: 1px solid #e6edf7;
}

.specificationBoxitem {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: center;
}

.specificationBoxitem-item {
	flex: 1;
	text-align: center;
	line-height: 39px;
	border-right: 1px solid #e6edf7;
	border-bottom: 1px solid #e6edf7;
}

.sp-one {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 39px;
}

.sp-two {
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #595959;
	line-height: 39px;
}

.input-value {
	width: 30%;
	line-height: 18px;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep input[type="number"]{
  -moz-appearance: textfield;
}

</style>
